globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"U2p_MOGDb05JyhOMWpFx7"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs"

const env = process.env.NEXT_ENV || process.env.NEXT_PUBLIC_ENV

if (["prod", "stage"].includes(env)) {
  Sentry.init({
    environment: env,

    dsn:
      env === "prod"
        ? "https://dcde91f04e80361fefb4c9cf22084d73@5entry2.exante.eu/15"
        : "https://430c05b7c24e2358dfe1670177af9770@5entry-dev.exante.eu/7",
    integrations: [Sentry.replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // ...

    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
